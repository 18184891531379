class criteriaService {
    constructor(
        $state,
        $rootScope,
        $filter,
        http,
        $http,
        tenderService,
        ntFlash,
        loadExternalDataService,
        criteriaRequestService,
        uuid4,
        md5,
    ) {
        this.uuid4 = uuid4
        this.md5 = md5
        this.ntFlash = ntFlash
        this.http =   $http
         this.$rootScope = $rootScope;

        this.tenderService = tenderService
        this.ExternalDataService =   loadExternalDataService;
        this.criteriaRequestService =   criteriaRequestService;

            // Фабрики для різних типів критеріїв
        this.criteriaFactory = {
          "CRITERION.EXCLUSION.NATIONAL.ANTI-CORRUPTION": this.createAntiCorruptionCriteria.bind(this),
          "CRITERION.OTHER.SUBJECT_OF_PROCUREMENT.LOCAL_ORIGIN_LEVEL": this.createLocalOriginCriteria.bind(this),
          "CRITERION.OTHER.CONTRACT.GUARANTEE": this.createOtherContractGuaranteeCriteria.bind(this),
          // інші типи критеріїв...
        };




    }
    setTender(tender){
        this.tender = tender
        this.isSingleLot = false
        this.isMultilotTender = false
        this.tender = this.tenderService.currentTender();
        this.tenderId = this.tender.id;
        this.criteriaRequestService.setTenderId(this.tenderId)
        angular.copy(this.tender.criteria, this.criteria);
        if(this.tender.criteria) {
            this.criteria = this.formatCriteria(this.tender.criteria)

            this.tender.criteria = []
            this.AddedCriteria = true;

        }
        console.log(this.criteria)
        // if(this.tender.criteria) {
        //     this.criteria = this.tender.criteria.map((el) => {
        //         const actions = {
        //             canEdit: el.classification.id === "CRITERION.EXCLUSION.BUSINESS.BANKRUPTCY"
        //         }
        //         el.edit = el.classification.id === "CRITERION.EXCLUSION.BUSINESS.BANKRUPTCY"
        //          if(el.classification.id !== 'CRITERION.EXCLUSION.CONTRIBUTIONS.PAYMENT_OF_TAXES'){
        //                     return el;
        //                 }
        //         return el;
        //     })
        //
        //     this.tender.criteria = []
        //     this.AddedCriteria = true;
        // }


        this.isGuaranteeCriteriasActive = false
        this.isContractGuaranteeCriteriasActive = false
        this.isLccCriteriasActive = {
            COST_OF_USE: (this.criteria || []).some(el => el.classification.id === 'CRITERION.OTHER.LIFE_CYCLE_COST.COST_OF_USE'),
            MAINTENANCE_COST: (this.criteria || []).some(el => el.classification.id === 'CRITERION.OTHER.LIFE_CYCLE_COST.MAINTENANCE_COST'),
            END_OF_LIFE_COST: (this.criteria || []).some(el => el.classification.id === 'CRITERION.OTHER.LIFE_CYCLE_COST.END_OF_LIFE_COST'),
            ECOLOGICAL_COST: (this.criteria || []).some(el => el.classification.id === 'CRITERION.OTHER.LIFE_CYCLE_COST.ECOLOGICAL_COST')
        }
        this.criteriaCheck()
    }
    getMultilotTender(){
        return this.isMultilotTender
    }
  formatCriteria(criteria) {
    return criteria.map(criterion => {
      // Визначити тип критерію та застосувати відповідну фабрику
      const factory = this.criteriaFactory[criterion.classification.id] || this.createDefaultCriteria.bind(this);
      return factory(criterion);
    });
  }

    // Фабрики для різних типів критеріїв
  createDefaultCriteria(criterion) {
    return {
      ...criterion,
      actions: {
        canEdit: () => false,
        shouldSave: () => this.shouldSaveSaveCriteria(criterion),
        saveCriteria: (crit) => this.saveCriteria(crit),
        canDelete: () => false,
        canEditRelatesTo: () => this.canEditRelatesTo(criterion),
        availableRelates: () => this.availableRelates(criterion),
        edit: () => this.editCriterion(criterion),
        delete: () => this.deleteCriterion(criterion),
        // інші дії...
      },
      requirementGroups: this.formatRequirementGroups(criterion.requirementGroups, criterion)
    };
  }

    // Форматування груп вимог
  formatRequirementGroups(groups, parentCriterion) {
    return groups.map(group => ({
      ...group,
      actions: {
        addRequirement: () => this.addRequirement(parentCriterion, group),
        canAddRequirement: () => false
      },
      requirements: this.formatRequirements(group.requirements, group, parentCriterion)
    }));
  }

    // Форматування груп вимог
  formatContractGuaranteeRequirementGroups(groups, parentCriterion) {
    return groups.map(group => ({
      ...group,
      actions: {
        addRequirement: () => this.addRequirement(parentCriterion, group),
        canAddRequirement: () => false
      },
      requirements: this.formatContractGuaranteeRequirements(group.requirements, group, parentCriterion)
    }));
  }

    // Форматування вимог
  formatRequirements(requirements, parentGroup, parentCriterion) {
    return requirements.map(requirement => {

        const result = {
            ...requirement,
            actions: {
                canEdit: () => this.canEditRequirement(requirement, parentGroup, parentCriterion),
                canEditDescription: () => true,
                canEditTitle: () => this.canEditRequirementTitle(requirement, parentGroup, parentCriterion),
                shouldSave: () => this.canEditRequirement(requirement, parentGroup, parentCriterion),
                canDelete: () => this.canDeleteRequirement(requirement, parentGroup, parentCriterion),
                edit: (req) => this.editRequirement(req, parentGroup, parentCriterion),
                delete: () => this.deleteRequirement(parentCriterion, parentGroup, requirement, requirement.status === 'active'),
                canAddEligible: (type) => this.canAddEligibleEvidence(requirement, parentGroup, parentCriterion, type),
                addEligibleEvidence: (type) => this.addEligibleEvidence(requirement, parentGroup, parentCriterion, type),
                canEditAllEligibleEvidence: (type) => this.canEditAllEligibleEvidence(requirement, parentGroup, parentCriterion)
            }
        }


        if(requirement.eligibleEvidences){
           result.eligibleEvidences = this.formatEligibleEvidences(requirement.eligibleEvidences, requirement, parentGroup, parentCriterion)
        }
        return result;
    });
  }

    // Форматування вимог
  formatContractGuaranteeRequirements(requirements, parentGroup, parentCriterion) {
    return requirements.map(requirement => {
        if(requirement.title === ''){
          requirement.title = "Іншій формі"
        }
        const result = {
            ...requirement,
            actions: {
                canEdit: () => true,
                canEditTitle: () =>  requirement.title === "Іншій формі",
                canEditDescription: () => false,
                canDelete: () => requirement.title === "Іншій формі",
                edit: (req) => this.editRequirement(req, parentGroup, parentCriterion),
                delete: () => this.deleteContractGuaranteeRequirement(parentCriterion, parentGroup, requirement, requirement.status === 'active'),

                canAddEligible: (type) => type === 'document',
                addEligibleEvidence: (type) => this.addEligibleEvidence(requirement, parentGroup, parentCriterion, 'document'),
                canEditAllEligibleEvidence: (type) => true,
                canEditAllEligibleEvidenceDocument: (type) => true
            }
        }


        if(requirement.eligibleEvidences){
           result.eligibleEvidences = this.formatEligibleEvidences(requirement.eligibleEvidences, requirement, parentGroup, parentCriterion)
        }
        return result;
    });
  }

  formatEligibleEvidences(eligibleEvidences, requirements, parentGroup, parentCriterion){
      return eligibleEvidences.map(evidences => {
        return {
            ...evidences,
            actions: {
                canEdit: () => this.canEditEligible(evidences, requirements, parentGroup, parentCriterion),
                canDelete: () => this.canDeleteEligible(evidences, requirements, parentGroup, parentCriterion),
                saveEligible: (evidence) => this.saveEligible(evidence, requirements, parentGroup, parentCriterion),
                saveActiveEligible: (evidence) => this.saveEligible(evidence, requirements, parentGroup, parentCriterion, true),
                deleteActiveEligible: (evidence) => this.deleteEligible(evidence, requirements, parentGroup, parentCriterion, true),
                deleteEligible: (evidence) => this.deleteEligible(evidence, requirements, parentGroup, parentCriterion),
            }
        }
      })
  }

  canEditRequirement(requirement, parentGroup, parentCriterion) {
     const listOfRequirements = [
            "CRITERION.OTHER.BID.GUARANTEE",
            "CRITERION.OTHER.CONTRACT.GUARANTEE",
         'CRITERION.SELECTION.TECHNICAL_PROFESSIONAL_ABILITY.TECHNICAL.EQUIPMENT',
                   'CRITERION.SELECTION.TECHNICAL_PROFESSIONAL_ABILITY.TECHNICAL.STAFF_FOR_CARRYING_SCOPE',
                   'CRITERION.SELECTION.TECHNICAL_PROFESSIONAL_ABILITY.REFERENCES.WORKS_PERFORMANCE',
                   'CRITERION.SELECTION.ECONOMIC_FINANCIAL_STANDING'
        ]
    return listOfRequirements.includes(parentCriterion.classification.id);
  }

  canEditRequirementTitle(requirement, parentGroup, parentCriterion){
       const listOfRequirements = [
         'CRITERION.SELECTION.TECHNICAL_PROFESSIONAL_ABILITY.TECHNICAL.EQUIPMENT',
                   'CRITERION.SELECTION.TECHNICAL_PROFESSIONAL_ABILITY.TECHNICAL.STAFF_FOR_CARRYING_SCOPE',
                   'CRITERION.SELECTION.TECHNICAL_PROFESSIONAL_ABILITY.REFERENCES.WORKS_PERFORMANCE',
                   'CRITERION.SELECTION.ECONOMIC_FINANCIAL_STANDING'
        ]
    return listOfRequirements.includes(parentCriterion.classification.id);
  }

  canDeleteRequirement(requirement, parentGroup, parentCriterion) {
        const listOfRequirements = [
            "CRITERION.EXCLUSION.NATIONAL.ANTI-CORRUPTION"
        ]


    // Логіка перевірки можливості видалення вимоги
    return listOfRequirements.includes(parentCriterion.classification.id);
  }


  shouldSaveSaveCriteria(criteria){
               const listOfRequirements = [
                    "CRITERION.OTHER.BID.VALIDITY_PERIOD",
                   'CRITERION.OTHER.BID.GUARANTEE',
                   'CRITERION.SELECTION.TECHNICAL_PROFESSIONAL_ABILITY.TECHNICAL.EQUIPMENT',
                   'CRITERION.SELECTION.TECHNICAL_PROFESSIONAL_ABILITY.TECHNICAL.STAFF_FOR_CARRYING_SCOPE',
                   'CRITERION.SELECTION.TECHNICAL_PROFESSIONAL_ABILITY.REFERENCES.WORKS_PERFORMANCE',
                   'CRITERION.SELECTION.ECONOMIC_FINANCIAL_STANDING'
               ]


    // Логіка перевірки можливості видалення вимоги
    return listOfRequirements.includes(criteria.classification.id) && criteria.id === undefined;
  }

  canEditRelatesTo(criterion){
        const listOfRequirements = [
            "CRITERION.OTHER.BID.GUARANTEE",
            'CRITERION.SELECTION.TECHNICAL_PROFESSIONAL_ABILITY.TECHNICAL.EQUIPMENT',
            'CRITERION.SELECTION.TECHNICAL_PROFESSIONAL_ABILITY.TECHNICAL.STAFF_FOR_CARRYING_SCOPE',
            'CRITERION.SELECTION.TECHNICAL_PROFESSIONAL_ABILITY.REFERENCES.WORKS_PERFORMANCE',
            'CRITERION.SELECTION.ECONOMIC_FINANCIAL_STANDING'
        ]
    return listOfRequirements.includes(criterion.classification.id);
  }

  availableRelates(criterion){
    const listOfLotCriteria = [
        'CRITERION.OTHER.BID.GUARANTEE',
        'CRITERION.SELECTION.TECHNICAL_PROFESSIONAL_ABILITY.TECHNICAL.EQUIPMENT',
        'CRITERION.SELECTION.TECHNICAL_PROFESSIONAL_ABILITY.TECHNICAL.STAFF_FOR_CARRYING_SCOPE',
        'CRITERION.SELECTION.TECHNICAL_PROFESSIONAL_ABILITY.REFERENCES.WORKS_PERFORMANCE',
        'CRITERION.SELECTION.ECONOMIC_FINANCIAL_STANDING'
    ]
        let relates = []

        if([ "CRITERION.OTHER.BID.GUARANTEE"].includes(criterion.classification.id)){
           relates.push('tender')
        }
        if(listOfLotCriteria.includes(criterion.classification.id)){
           relates.push('lot')
        }

        return relates
  }

  canAddEligibleEvidence(requirement, parentGroup, parentCriterion, type) {
          let listOfRequirements = [
            "CRITERION.OTHER.BID.VALIDITY_PERIOD",
              'CRITERION.OTHER.BID.GUARANTEE'
        ]
      if(type === 'document'){
          listOfRequirements = [
              ...listOfRequirements,
              'CRITERION.OTHER.SUBJECT_OF_PROCUREMENT.LOCAL_ORIGIN_LEVEL',
              'CRITERION.SELECTION.TECHNICAL_PROFESSIONAL_ABILITY.TECHNICAL.EQUIPMENT',
              'CRITERION.SELECTION.TECHNICAL_PROFESSIONAL_ABILITY.TECHNICAL.STAFF_FOR_CARRYING_SCOPE',
              'CRITERION.SELECTION.TECHNICAL_PROFESSIONAL_ABILITY.REFERENCES.WORKS_PERFORMANCE',
              'CRITERION.SELECTION.ECONOMIC_FINANCIAL_STANDING'
          ]

          return listOfRequirements.includes(parentCriterion.classification.id);
      }
      if(type === 'statement'){
          return listOfRequirements.includes(parentCriterion.classification.id);
      }

  }

  canEditEligible(evidences, requirements, parentGroup, parentCriterion){

           const listOfRequirements = [
            "CRITERION.OTHER.BID.VALIDITY_PERIOD",
               'CRITERION.OTHER.BID.GUARANTEE',
                'CRITERION.SELECTION.TECHNICAL_PROFESSIONAL_ABILITY.TECHNICAL.EQUIPMENT',
              'CRITERION.SELECTION.TECHNICAL_PROFESSIONAL_ABILITY.TECHNICAL.STAFF_FOR_CARRYING_SCOPE',
              'CRITERION.SELECTION.TECHNICAL_PROFESSIONAL_ABILITY.REFERENCES.WORKS_PERFORMANCE',
              'CRITERION.SELECTION.ECONOMIC_FINANCIAL_STANDING'
        ]


    // Логіка перевірки можливості видалення вимоги
    return listOfRequirements.includes(parentCriterion.classification.id);
  }

  canDeleteEligible(evidences, requirements, parentGroup, parentCriterion){
        const listOfRequirements = [
            "CRITERION.OTHER.BID.VALIDITY_PERIOD",
            "CRITERION.OTHER.BID.GUARANTEE",
             'CRITERION.SELECTION.TECHNICAL_PROFESSIONAL_ABILITY.TECHNICAL.EQUIPMENT',
              'CRITERION.SELECTION.TECHNICAL_PROFESSIONAL_ABILITY.TECHNICAL.STAFF_FOR_CARRYING_SCOPE',
              'CRITERION.SELECTION.TECHNICAL_PROFESSIONAL_ABILITY.REFERENCES.WORKS_PERFORMANCE',
              'CRITERION.SELECTION.ECONOMIC_FINANCIAL_STANDING'
        ]


    // Логіка перевірки можливості видалення вимоги
    return listOfRequirements.includes(parentCriterion.classification.id);
  }

    canEditAllEligibleEvidence(requirements, parentGroup, parentCriterion){
        const listOfRequirements = [
            "CRITERION.OTHER.BID.VALIDITY_PERIOD",
            "CRITERION.OTHER.BID.GUARANTEE",
            'CRITERION.SELECTION.TECHNICAL_PROFESSIONAL_ABILITY.TECHNICAL.EQUIPMENT',
            'CRITERION.SELECTION.TECHNICAL_PROFESSIONAL_ABILITY.TECHNICAL.STAFF_FOR_CARRYING_SCOPE',
            'CRITERION.SELECTION.TECHNICAL_PROFESSIONAL_ABILITY.REFERENCES.WORKS_PERFORMANCE',
            'CRITERION.SELECTION.ECONOMIC_FINANCIAL_STANDING'
        ]


    // Логіка перевірки можливості видалення вимоги
    return listOfRequirements.includes(parentCriterion.classification.id);
  }

  saveCriteria(criteria) {
        console.log(criteria)
        const request =
            {
                ...criteria,
                requirementGroups: criteria.requirementGroups.map(group => {
                    const newGroup = {...group}
                    delete newGroup.actions
                    newGroup.requirements = group.requirements.map(requirement => {
                        const newRequirement = {...requirement}
                        delete newRequirement.actions

                        if(requirement.tempExpectedValues){
                            newRequirement.expectedValues = requirement.tempExpectedValues
                            delete newRequirement.tempExpectedValues
                            delete newRequirement.minValue
                            delete newRequirement.maxValue
                        }
                        if(requirement.expectedValue){
                           delete newRequirement.tempExpectedValues;
                           delete newRequirement.minValue
                            delete newRequirement.maxValue
                        }
                        if(requirement.eligibleEvidences){
                            newRequirement.eligibleEvidences = newRequirement.eligibleEvidences.map(evidences => {
                                delete evidences.actions
                                return {...evidences}
                            })
                        }

                        return newRequirement

                    })
                    return newGroup
                })
            }
        delete request.actions;


        this.criteriaRequestService.saveCriteria(request)
            .then((data)=>{
                console.log(data)
                let criteria = this.criteria.find((crit) => {
                    crit.id === data[0].id

                })
                criteria = {
                    ...criteria,
                    ...data[0]
                }

            })

  }

  saveEligible(evidences, requirement, parentGroup, parentCriterion, active = false){
    if(active){

        let  requirements = {...requirement};
        console.log(requirement)
        const evidences = requirements.eligibleEvidences.map((eligible)=>{
            return {
                title: eligible.title,
                type: eligible.type,
                description: eligible.description
            }
        })


        const data = {
            eligibleEvidences: evidences
        }

        this.criteriaRequestService.editActiveEligibleEvidence(
            'create',
            parentCriterion.id,
            parentGroup.id,
            requirement,
            data
        )
            .then((data)=>{
                return data.data
            })
            .then((requirement)=>{
                let currentCriteria = this.criteria.find(el=> el.id === parentCriterion.id)

                let currentGroup =   currentCriteria.requirementGroups.find(el=>el.id ===  parentGroup.id )

                let archiveRequirement = {
                    dataType: "boolean",
                    dateModified: requirement[1].dateModified,
                    datePublished: requirement[1].dateModified,
                    eligibleEvidences: requirement[0].eligibleEvidences,
                    expectedValue: requirement[0].expectedValue,
                    id: requirement[1].id,
                    status: "cancelled",
                    title: requirement[0].title ,
                }

              currentGroup.requirements.splice( currentGroup.requirements.length - 1, 0, archiveRequirement )
            })
            .catch((error)=>{
                console.log('criteriaForm editEligibleEvidence', error)
            })


        } else {

            let eligible = {};
            angular.copy(evidences, eligible)
            delete eligible.actions;

            this.criteriaRequestService.editDraftEligibleEvidence(
                (eligible.id !== undefined) ? 'edit_draft' : 'create',
                parentCriterion.id,
                parentGroup.id,
                requirement.id,
                eligible.id,
                eligible
            )
                .then((data) => {
                    const crit = this.criteria.find((crit) => crit.id === parentCriterion.id)
                    const groups = crit.requirementGroups.find((groups) => groups.id === parentGroup.id)
                    const requirements = groups.requirements.find((req) => req.id === requirement.id)
                    if (evidences.id === undefined) {
                        requirements.eligibleEvidences = this.formatEligibleEvidences((requirement.eligibleEvidences && requirement.eligibleEvidences.length > 0)
                            ? [...requirement.eligibleEvidences, data.data] : [data.data], requirements, groups, crit)

                    } else {
                        let eligibleEvidence = requirements.eligibleEvidences.filter((el) => el.id !== data.data.id)
                        requirements.eligibleEvidences = this.formatEligibleEvidences([...eligibleEvidence, data.data], requirements, groups, crit)
                    }
                    this.notifyCriteriaChanged('edited', parentCriterion);
                    this.ntFlash.success('Отредактировано');


                })
                .catch((error) => {
                    console.log('criteriaForm editEligibleEvidence', error)
                })
        }
  }

  deleteEligible(evidences, requirements, parentGroup, parentCriterion, active = false){
    if(active){
        if(evidences.id){
            this.criteriaRequestService.editActiveEligibleEvidence(
                'delete',
                parentCriterion.id,
                parentGroup.id,
                requirements,
                null,
                evidences.id
            )
                .then((data)=>{
                    if (requirements.eligibleEvidences.length > 0)
                        requirements.eligibleEvidences.splice(index, 1);
                    if (requirements.eligibleEvidences.length === 0)
                        delete requirements.eligibleEvidences;
                    this.ntFlash.success('Удалено успешно');
                })
                .catch((error)=>{
                    console.log('criteriaForm removeEligibleEvidence', error)
                })
        } else {
            if (requirements.eligibleEvidences.length > 0)
                requirements.eligibleEvidences.splice(index, 1);
            if (requirements.eligibleEvidences.length === 0)
                delete requirements.eligibleEvidences;
        }
        this.notifyCriteriaChanged('edited', parentCriterion);
    } else {


        this.criteriaRequestService.editDraftEligibleEvidence(
            'delete',
            parentCriterion.id,
            parentGroup.id,
            requirements.id,
            evidences.id
        )
            .then((data) => {
                const crit = this.criteria.find((crit) => crit.id === parentCriterion.id)
                const groups = crit.requirementGroups.find((groups) => groups.id === parentGroup.id)
                const requirement = groups.requirements.find((req) => req.id === requirements.id)

                const index = requirement.eligibleEvidences.findIndex(evidences => evidences.id === data.data.id)


                if (requirements.eligibleEvidences.length > 0)
                    requirements.eligibleEvidences.splice(index, 1);
                if (requirements.eligibleEvidences.length === 0)
                    delete requirements.eligibleEvidences;

                this.notifyCriteriaChanged('edited', parentCriterion);
                this.ntFlash.success('Удалено успешно');
            })
            .catch((error) => {
                console.log('criteriaForm removeEligibleEvidence', error)
            })
    }
  }



    // Методи для виконання дій
  editCriterion(criterion) {
        console.log(criterion)

    // Логіка редагування критерію
  }

  addEligibleEvidence(requirement, parentGroup, parentCriterion, type) {
        console.log(type)
    let emptyEvidence = {
        title: "Документальне підтвердження",
        description: "Довідка в довільній формі",
        type,
    }
    emptyEvidence.actions = {
            canEdit: () => this.canEditEligible(emptyEvidence, requirement, parentGroup, parentCriterion),
            canDelete: () => this.canDeleteEligible(emptyEvidence, requirement, parentGroup, parentCriterion),
            saveEligible: (evidence) => this.saveEligible(evidence, requirement, parentGroup, parentCriterion),
            saveActiveEligible: (evidence) => this.saveEligible(evidence, requirements, parentGroup, parentCriterion, true),
            deleteActiveEligible: (evidence) => this.deleteEligible(evidence, requirements, parentGroup, parentCriterion, true),
            deleteEligible: (evidence) => this.deleteEligible(evidence, requirement, parentGroup, parentCriterion),
        }
    const crit = this.criteria.find((crit)=>crit.id === parentCriterion.id)
      const groups = crit.requirementGroups.find((groups)=> groups.id === parentGroup.id)
      const requirements = groups.requirements.find((req)=> req.id === requirement.id)
                         requirements.eligibleEvidences = (requirement.eligibleEvidences && requirement.eligibleEvidences.length > 0)
      ? [...requirement.eligibleEvidences, emptyEvidence] : [emptyEvidence]


    this.notifyCriteriaChanged('edited', parentCriterion);
  }




  deleteCriterion(criterion) {
    console.log(criterion)
    const index = this.criteria.findIndex(c => c.id === criterion.id);
    if (index !== -1) {
      this.criteria.splice(index, 1);
    }
  }

  addRequirement(parentCriterion, group){
        console.log(parentCriterion)
        console.log(group)

  }



  createAntiCorruptionCriteria(criterion) {
    return {
      ...criterion,
      actions: {
        canEdit: () => false,
        canDelete: () => false,
        edit: () => this.editCriterion(criterion),
        delete: () => this.deleteCriterion(criterion),
        // специфічні дії...
      },
      requirementGroups: this.formatRequirementGroups(criterion.requirementGroups, criterion)
    };
  }

  createLocalOriginCriteria(criterion) {
    return {
      ...criterion,
      actions: {
        canEdit: () => true,
        canDelete: () => true,
        edit: () => this.editCriterion(criterion),
        delete: () => this.deleteCriterion(criterion),
        // специфічні дії...
      },
      requirementGroups: this.formatRequirementGroups(criterion.requirementGroups, criterion),
      relatesTo: 'item',
      relatedItem: this.tender.items[0].id
    };
  }

  createOtherContractGuaranteeCriteria(criterion) {
        console.log(criterion)
    return {
      ...criterion,
      relatesTo : this.getMultilotTender() ? 'lot': 'tender',
      relatedItem : this.getMultilotTender() ? this.tender.lots[0].id : null,
      actions: {
        canEdit: () => true,
        shouldSave: () => true,
        saveCriteria: (crit) => this.saveCriteria(crit),
        canDelete: () => criterion.id === undefined,
        edit: () => this.editCriterion(criterion),
        delete: () => this.deleteCriterion(criterion),
        // специфічні дії...
      },
      requirementGroups: this.formatContractGuaranteeRequirementGroups(criterion.requirementGroups, criterion),

    };
  }


  editRequirement( requirement, parentGroup, parentCriterion) {

        const request = {
            ...requirement
        }
        delete request.actions;
        delete request.eligibleEvidences;
        if(requirement.tempExpectedValues){
            request.expectedValues = requirement.tempExpectedValues
            delete request.tempExpectedValues;
            delete request.minValue
            delete request.maxValue
        }
        if(requirement.expectedValue){
           delete request.tempExpectedValues;
           delete request.minValue
            delete request.maxValue
        }



    this.criteriaRequestService.editRequirement(request, parentGroup, parentCriterion, this.tender.status === 'active.tendering')
       .then((data)=>{
           console.log(data)
           let criteria = this.criteria.find((crit) => {
               crit.id === data.data[0].id

           })
           criteria = {
               ...data.data[0]
           }

       })
  }


    saveCriterias(criteria, create){
        if(create){
           return this.http.post('/opc/tender/'+ this.tenderId +'/criterias', criteria)
                    .then((data)=>{
                        return data.data
                    })
                    .catch(error => {
                        console.log('criteriaService.saveCriterias error', error);
                        return error
                    })
        } else {
            return this.http.patch('/opc/tender/'+ this.tenderId +'/criterias', criteria)
                    .then((data)=>{
                        return data.data
                    })
                    .catch(error => {
                        console.log('criteriaService.saveCriterias error', error);
                        return error
                    })
        }
    }

    removeCriteria(criteria){
        return this.http.patch('/opc/tender/'+ this.tenderId +`/criterias/${criteria.id}`)
                    .then((data)=>{
                        return data.data
                    })
                    .catch(error => {
                        console.log('criteriaService.saveCriterias error', error);
                        return error
                    })
    }

    deleteContractGuaranteeRequirement(criteria, group, requirement, edit){
        if(requirement.id === undefined){

            const crit = this.criteria.find((crit)=>crit.id === criteria.id)
            const groups = crit.requirementGroups.find((groups)=> groups.id === group.id)

            index = group.requirements.findIndex((req)=> req.title === requirement.title)
            groups.requirements.splice(index, 1)

             this.notifyCriteriaChanged('edited', criteria);
        } else {
            this.deleteRequirement(criteria, group, requirement, edit)
        }



    }

    deleteRequirement(criteria, group, requirement, edit){
        return this.criteriaRequestService.deleteRequirement(
            this.tenderId,
            criteria.id,
            group.id,
            requirement.id,
            edit,
            this.tender.status === 'active.tendering'
            )
            .then((data)=>{
                const crit = this.criteria.find((crit)=>crit.id === criteria.id)
                const groups = crit.requirementGroups.find((groups)=> groups.id === group.id)
                const requirements = groups.requirements.find((req)=> req.id === requirement.id)
                requirements.status = data.data.status
                this.notifyCriteriaChanged('edited', criteria);
            })
    }

    notifyCriteriaChanged(action, criterion) {
      this.$rootScope.$broadcast('criteriaChanged', {
        action: action, // 'edited', 'deleted', 'added', etc.
        criterionId: criterion.id,
        criterion: criterion
      });
    }



    // addEvidence(criteria, requirementGroup, requirement, type ){
    //     let emptyEvidence = {
    //         "title": "Документальне підтвердження",
    //         "description": "Довідка в довільній формі",
    //         "type": "document"
    //     }
    //     if(type === 'statement'){
    //       emptyEvidence.type = 'statement'
    //     }
    //      requirement.eligibleEvidences = (requirement.eligibleEvidences && requirement.eligibleEvidences.length > 0)
    //          ? [...requirement.eligibleEvidences, emptyEvidence] : [emptyEvidence]
    //
    // }


    addBidValidityPeriod(){
        return  this.ExternalDataService.getOtherCriterias()
            .then((o_crit)=>{
                let currentCriterias = o_crit.find((el)=>el.classification.id === 'CRITERION.OTHER.BID.VALIDITY_PERIOD')
                this.criteria.push(this.createDefaultCriteria(currentCriterias))
                this.notifyCriteriaChanged('edited', currentCriterias);
            })
    }

    addGuaranteeCriteria() {
       return  this.ExternalDataService.getGuaranteeCriterias()
           .then(data => {
               (data || []).forEach(el => {
                   el.relatesTo = this.getMultilotTender() ? 'lot': 'tender';
                   el.relatedItem = this.getMultilotTender() ? this.tender.lots[0].id : null;
                   this.criteria.push(this.createDefaultCriteria(el))
               });
               this.notifyCriteriaChanged('edited', this.criteria);
           })
    }

    addContractGuaranteeCriteria ()  {
       return  this.ExternalDataService.getContractGuaranteeCriterias()
            .then(data => {
                (data || []).forEach(el => {
                    this.criteria.push(this.createOtherContractGuaranteeCriteria(el))
                    console.log(el)
                });
               this.notifyCriteriaChanged('edited', this.criteria);

            })
    }

    addArticle16Criteria(id){
        return this.ExternalDataService.classifierObjections16()
            .then(data => {
                const criteria = data.find((criteria) => criteria.classification.id === id)
               criteria.relatesTo = this.getMultilotTender() ? 'lot': 'tender';
                   criteria.relatedItem = this.getMultilotTender() ? this.tender.lots[0].id : null;
                   this.criteria.push(this.createDefaultCriteria(criteria))
               this.notifyCriteriaChanged('edited', this.criteria);
           })
            // .then((data) => {
            //     const criteria = data.filter((criteria) => criteria.classification.id === id)
            //        console.log(criteria)
            //         criteria[0].requirementGroups[0].requirements[0] = {
            //             title: criteria[0].description,
            //             dataType: criteria[0].requirementGroups[0].requirements[0].dataType,
            //             expectedValue: criteria[0].requirementGroups[0].requirements[0].expectedValue
            //         }
            //         if(criteria[0].requirementGroups[0].requirements[0].eligibleEvidence !== undefined) {
            //           criteria[0].requirementGroups[0].requirements[0].eligibleEvidences = [
            //               {
            //                     type: criteria[0].requirementGroups[0].requirements[0].eligibleEvidence.type,
            //                     title: criteria[0].description,
            //                     description: criteria[0].requirementGroups[0].requirements[0].eligibleEvidence.description
            //                 }
            //           ]
            //         }
            //
            //     this.criteria = criteria.map((crit) => {
            //         return {
            //             ...crit,
            //             must_save: true,
            //             edit: true,
            //             relatesTo: this.getMultilotTender() ? 'lot': 'tender',
            //             relatedItem: this.getMultilotTender() ? this.tender.lots[0].id : null,
            //         }
            //     })
            //
            //         return this.criteria;
            // });

    }


    getCriteria(){
        return this.criteria
    }
    setCriteria(criteria){
        this.criteria = this.formatCriteria(criteria)
    }

    addLocalOriginLevel() {
       return  this.ExternalDataService.local_origin_level()
           .then((data) => {
               console.log(data)
                this.criteria.push(this.createLocalOriginCriteria(data[0]))
                this.notifyCriteriaChanged('edited', this.criteria);
           })
                .catch(error => {
                    console.log('criteriaService.addCriterias error', error);
                })
    }

    addCriteria(criteria) {
        this.criteria = [...this.criteria, ...criteria]
    }

    criteriaCheck(){

        if(this.criteria){
            this.criteria.forEach((el)=>{

                this.setGuaranteeCriterias(el.classification.id, true)

            })
        }
    }

    addCriterias(){
        return this.ExternalDataService.getCriterias()
            .then((data) => {
                let criteria = data.map((crit) => {
                    // crit.edit = crit.classification.id === 'CRITERION.EXCLUSION.BUSINESS.BANKRUPTCY';

                    return crit
                })

                if(['aboveThreshold', 'competitiveOrdering'].includes(this.tender.procurementMethodType)){
                    let criterias = criteria.filter((el)=>{
                        if(el.classification.id !== 'CRITERION.EXCLUSION.CONTRIBUTIONS.PAYMENT_OF_TAXES'){
                            return el;
                        }
                    })
                    this.criteria = criterias
                    return criterias;
                } else if (!['aboveThresholdEU', 'aboveThresholdUA.defense', 'simple.defense', 'closeFrameworkAgreementSelectionUA', 'esco'].includes(this.tender.procurementMethodType)){
                    return  this.ExternalDataService.getOtherCriterias()
                        .then((o_crit)=>{
                            this.criteria = [...criteria, ...o_crit].map((crit) => {
                                if(!crit.relatesTo){

                                    crit.relatesTo = 'tenderer'
                                }
                                return {
                                    ...crit,
                                }
                            })
                            criteria = [...criteria, ...o_crit]
                            this.criteria = criteria
                            return criteria;
                        })
                }

                return criteria
            })
            .then((criteria)=>{
                // console.log(criteria)
                return this.http.post('/opc/tender/'+ this.tenderId +'/criterias', criteria)
                    .then((data)=>{
                        return data.data
                    })
                    .catch(error => {
                        console.log('criteriaService.saveCriterias error', error);
                        return error
                    })
            });
    }



    addLangCriterias(){
        return  this.ExternalDataService.getOtherCriterias()
            .then((o_crit)=>{
                let criteriasLang = o_crit.filter((el)=>el.classification.id === 'CRITERION.OTHER.BID.LANGUAGE')

                // criteriasLang.edit = false
                // this.criteria = [...this.criteria, criteriasLang]
                return criteriasLang;
            })
            .then((criteria)=>{
                return this.http.post('/opc/tender/'+ this.tenderId +'/criterias', criteria)
                    .then((data)=>{
                        return data.data
                    })
                    .catch(error => {
                        console.log('criteriaService.saveCriterias error', error);
                        return error
                    })
            });
    }

















    addLccCriteria(id) {
        return  this.ExternalDataService.getLccCriterias()
            .then(data => {
                const criteria = (data || []).find(el => el.classification.id === 'CRITERION.OTHER.LIFE_CYCLE_COST.' + id);
                if (criteria) {
                    criteria.id = md5.createHash(uuid4.generate()),
                        criteria.relatesTo = this.tender.lots.length ? 'lot' : 'tender';
                    if (this.isMultilotTender) criteria.relatedItem = '';
                    if (!Array.isArray(this.criteria)) this.criteria = [];
                    this.criteria = [...this.criteria, criteria];
                    this.isLccCriteriasActive[id] = true;
                }
            })
    }

    saveOptionalCriteria(criteria, update = true, edit = false){

        if(update){

            if(criteria.must_save){
                delete criteria.must_save
            }
            if(criteria.edit){
                delete criteria.edit
            }

            criteria.requirementGroups.forEach((requirementGroup)=>{
                requirementGroup.requirements.forEach((requirement)=> {

                    if (requirement.requirementForm) {
                        delete requirement.requirementForm
                    }
                    if (requirement.eligibleEvidences) {
                        requirement.eligibleEvidences.forEach((eligibleEvidence) => {
                            if (eligibleEvidence.eligibleEvidenceForm) {
                                delete eligibleEvidence.eligibleEvidenceForm
                            }
                        })
                    }
                })

            })

            return this.http.post('/opc/tender/'+ this.tenderId +'/criterias/', criteria)
                .then((data)=>{
                    (data.data || []).forEach((el)=>{
                        this.setGuaranteeCriterias(el.classification.id, true);
                    })
                    return data.data
                })
                .catch((error) => {
                    console.log(error)
                })
        } else {

            const promises = [];
            criteria.requirementGroups.forEach((requirementGroup)=>{
                requirementGroup.requirements.forEach((requirement)=>{

                    if(requirement.requirementForm){
                        delete requirement.requirementForm
                    }
                    if(requirement.eligibleEvidences){
                        requirement.eligibleEvidences.forEach((eligibleEvidence)=>{
                            if(eligibleEvidence.eligibleEvidenceForm){
                                delete eligibleEvidence.eligibleEvidenceForm
                            }
                        })
                    }

                    promises.push(this.requestOptionalRequirement(criteria.id, requirementGroup.id, requirement, edit))

                })

            })


            return Promise.all(promises)
                .then((data)=>{
                    console.log(data);
                    return data
                })
        }

    }

    requestOptionalRequirement(criteria_id, group_id, requirement, edit = false){

        const data = {
            description: requirement.description
        }
        let link = `/opc/tender/${this.tenderId}/criterias/${criteria_id}/requirement-groups/${group_id}/requirements/${requirement.id}/`

        if(edit){
            return this.http.put(link, data)
                .then((data)=>{
                    return data
                })


        } else {
            return this.http.patch(link, data)
                .then((data)=>{
                    return data
                })

        }

    }



    restoreRequirement(criteria_id, group_id, requirement_id, edit){

        let link = `/opc/tender/${this.tenderId}/criterias/${criteria_id}/requirement-groups/${group_id}/requirements/${requirement_id}/`

        let data = {status: 'active'}

        // return this.http.patch(link, data)
        //         .then((data)=>{
        //             console.log(data)
        //         })
        const criteria = this.criteria.find((crit)=>crit.id === criteria_id)
        const group =  criteria.requirementGroups.find((group)=>group.id === group_id)
        let requirement =  group.requirements.find((req)=>req.id === requirement_id)

        if(edit){
            return this.http.put(link, data)
                .then((data)=>{
                    group.requirements.push(data.data)
                    return data
                })
        } else {
            return this.http.patch(link, data)
                .then((data)=>{
                    requirement = data.data

                    return data
                })
        }

    }



    // deleteOptionalCriteria(criteria){}


    isOptional(criteria)  {
        return [

            'CRITERION.OTHER.SUBJECT_OF_PROCUREMENT.LOCAL_ORIGIN_LEVEL',
            'CRITERION.OTHER.BID.GUARANTEE',
            'CRITERION.OTHER.CONTRACT.GUARANTEE',
            'CRITERION.OTHER.LIFE_CYCLE_COST.COST_OF_USE',
            'CRITERION.OTHER.LIFE_CYCLE_COST.MAINTENANCE_COST',
            'CRITERION.OTHER.LIFE_CYCLE_COST.END_OF_LIFE_COST',
            'CRITERION.OTHER.LIFE_CYCLE_COST.ECOLOGICAL_COST',
            'CRITERION.SELECTION.TECHNICAL_PROFESSIONAL_ABILITY.TECHNICAL.STAFF_FOR_CARRYING_SCOPE',
            'CRITERION.SELECTION.TECHNICAL_PROFESSIONAL_ABILITY.REFERENCES.WORKS_PERFORMANCE',
            'CRITERION.SELECTION.TECHNICAL_PROFESSIONAL_ABILITY.TECHNICAL.EQUIPMENT',
            'CRITERION.SELECTION.ECONOMIC_FINANCIAL_STANDING'

        ].includes(criteria.classification.id);
    }

    isLcc(criteria)  {
        return [
            'CRITERION.OTHER.LIFE_CYCLE_COST.COST_OF_USE',
            'CRITERION.OTHER.LIFE_CYCLE_COST.MAINTENANCE_COST',
            'CRITERION.OTHER.LIFE_CYCLE_COST.END_OF_LIFE_COST',
            'CRITERION.OTHER.LIFE_CYCLE_COST.ECOLOGICAL_COST'
        ].includes(criteria.classification.id);
    }



    editDraftEligibleEvidence(type= 'create', criteria_id, group_id, requirement_id, evidence_id = null, data ){
        let resource = ''
        if(evidence_id){
            resource = `/opc/tender/${this.tenderId }/criterias/${criteria_id}/requirement-groups/${group_id}/requirements/${requirement_id}/evidences/${evidence_id}/`;
        } else {
            resource = `/opc/tender/${this.tenderId }/criterias/${criteria_id}/requirement-groups/${group_id}/requirements/${requirement_id}/evidences/`;
        }

        let request;
        switch (type){
            case 'create': {
                request =  this.http.post(resource, data)
            }
            break;
            case 'edit_draft': {
                request =  this.http.patch(resource, data)
            }
            break;
            case 'delete': {
                request =   this.http.delete(resource)
            }
            break;
        }


        return request
            .then((response) => {
                return response
            })

    }







        // return this.http.patch('/opc/tender/'+ this.tenderId +'/criterias/' + criteria.id+ '/', data)
        //     .then((data)=>{
        //         console.log(data)
        //         return data.data
        //     })
        //     .catch(error => {
        //         console.log('criteriaService.saveCriterias error', error);
        //     })

    // }


    setGuaranteeCriterias(name, status){
        switch (name) {
            case 'CRITERION.OTHER.BID.GUARANTEE': this.isGuaranteeCriteriasActive = status; break;
            case 'CRITERION.OTHER.CONTRACT.GUARANTEE': this.isContractGuaranteeCriteriasActive = status; break;
            case 'CRITERION.OTHER.LIFE_CYCLE_COST.COST_OF_USE': this.isLccCriteriasActive.COST_OF_USE = status; break;
            case 'CRITERION.OTHER.LIFE_CYCLE_COST.MAINTENANCE_COST': this.isLccCriteriasActive.MAINTENANCE_COST = status; break;
            case 'CRITERION.OTHER.LIFE_CYCLE_COST.END_OF_LIFE_COST': this.isLccCriteriasActive.END_OF_LIFE_COST = status; break;
            case 'CRITERION.OTHER.LIFE_CYCLE_COST.ECOLOGICAL_COST': this.isLccCriteriasActive.ECOLOGICAL_COST = status; break;
        }
    }

    getGuaranteeCriterias(name){
        console.log(this.isContractGuaranteeCriteriasActive)
        switch (name) {
            case 'CRITERION.OTHER.BID.GUARANTEE': return this.isGuaranteeCriteriasActive;
            case 'CRITERION.OTHER.CONTRACT.GUARANTEE': return this.isContractGuaranteeCriteriasActive;
            case 'CRITERION.OTHER.LIFE_CYCLE_COST.COST_OF_USE': return this.isLccCriteriasActive.COST_OF_USE;
            case 'CRITERION.OTHER.LIFE_CYCLE_COST.MAINTENANCE_COST': return this.isLccCriteriasActive.MAINTENANCE_COST;
            case 'CRITERION.OTHER.LIFE_CYCLE_COST.END_OF_LIFE_COST': return this.isLccCriteriasActive.END_OF_LIFE_COST;
            case 'CRITERION.OTHER.LIFE_CYCLE_COST.ECOLOGICAL_COST': return this.isLccCriteriasActive.ECOLOGICAL_COST;
        }
    }


    setSingleLot(status){
        this.isSingleLot = status
    }

    getSingleLot(){
        // console.log(this.isSingleLot);
        return this.isSingleLot
    }


    setMultilotTender(status){
        this.isMultilotTender = status
    }



}
commons.service('criteriaService', criteriaService);

class criteriaRequestService {
    constructor(
        $state,
        $filter,
        http,
        $http,
        tenderService
    ) {
        this.http = $http
        this.tenderService = tenderService
        this.tenderId = ''
    }

    setTenderId(id){
        this.tenderId = id
    }

    deleteRequirement(tenderId, criteria_id, group_id, requirement_id, status, edit){

        let link = `/opc/tender/${tenderId}/criterias/${criteria_id}/requirement-groups/${group_id}/requirements/${requirement_id}/`
        const data = {status: (status)? 'cancelled' : 'active'};

        if(edit){
            return this.http.put(link, data)
                .then((data)=>{
                    return data
                })
        } else {
            return this.http.patch(link, data)
                .then((data)=>{
                    return data
                })
        }

    }


    saveCriteria(criteria){

        if(criteria.id){
            return this.http.patch('/opc/tender/'+ this.tenderId +'/criterias/' + criteria.id + '/', criteria)
                    .then((data)=>{
                        return data.data
                    })
                    .catch(error => {
                        console.log('criteriaService.saveCriterias error', error);
                        return error
                    })
        } else {
         return this.http.post('/opc/tender/'+ this.tenderId +'/criterias', criteria)
                    .then((data)=>{
                        return data.data
                    })
                    .catch(error => {
                        console.log('criteriaService.saveCriterias error', error);
                        return error
                    })
        }



    }

    editRequirement(requirement, parentGroup, parentCriterion, edit = false){


        const request = {
            ...requirement
        }
        delete request.id
        delete request.datePublished
        let link = `/opc/tender/${this.tenderId}/criterias/${parentCriterion.id}/requirement-groups/${parentGroup.id}/requirements/${requirement.id}/`

        if(edit){
            return this.http.put(link, request)
                .then((data)=>{
                    return data
                })


        } else {
            return this.http.patch(link, request)
                .then((data)=>{
                    return data
                })

        }

    }


    editDraftEligibleEvidence(type= 'create', criteria_id, group_id, requirement_id, evidence_id = null, data ){
        let resource = ''
        if(evidence_id){
            resource = `/opc/tender/${this.tenderId }/criterias/${criteria_id}/requirement-groups/${group_id}/requirements/${requirement_id}/evidences/${evidence_id}/`;
        } else {
            resource = `/opc/tender/${this.tenderId }/criterias/${criteria_id}/requirement-groups/${group_id}/requirements/${requirement_id}/evidences/`;
        }

        let request;
        switch (type){
            case 'create': {
                request =  this.http.post(resource, data)
            }
            break;
            case 'edit_draft': {
                delete data.id;
                request =  this.http.patch(resource, data)
            }
            break;
            case 'delete': {
                request =   this.http.delete(resource)
            }
            break;
        }


        return request
            .then((response) => {
                return response
            })

    }


    editActiveEligibleEvidence(type= 'create', criteria_id, group_id, requirement,  data, evidence_id = null ){
        let resource = ''
        let request;

        switch (type){

            case 'delete': {
                resource = `/opc/tender/${this.tenderId }/criterias/${criteria_id}/requirement-groups/${group_id}/requirements/${requirement.id}/evidences/${evidence_id}/`;
                request =   this.http.delete(resource)
            }
                break;
            default: {
                resource = `/opc/tender/${this.tenderId }/criterias/${criteria_id}/requirement-groups/${group_id}/requirements/${requirement.id}/`;
                request =  this.http.put(resource, data)
            }
        }


        return request
            .then((response) => {
                return response
            })

    }





}

commons.service('criteriaRequestService', criteriaRequestService);